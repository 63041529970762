import React from 'react';
import { Link, withPrefix } from 'gatsby';
import { Container, Hero, Content, Footer, Columns, Element } from 'react-bulma-components';

export default ({ isIndex }) => {

    /*const docs = false ? (
        <Columns.Column size={3}>
            <ul>
                <li>
                    <Link to="/docs">Documentation</Link>
                </li>
                <li>
                    <Link to="/tutorial">Tutorial</Link>
                </li>
            </ul>
        </Columns.Column>
    ) : null; */
    const docs = null;

    const style = isIndex ? null : {
        marginTop: 0
    };
    return (
        <Hero className="footer-section" style={style}>
        <Hero.Footer>
            <Footer style={{ textAlign: 'center' }}>
                <Container>
                    <Element renderAs={Link} to={"/"} style={{ marginBottom: '20px'}}>
                        <img
                        src={`${withPrefix('/')}img/Model_Broker_logo.svg`} style={{margin: 0, height: '58px'}} />
                    </Element>
                    <Columns>
                        <Columns.Column size={4} offset={4}>
                            <ul>
                                <li>
                                    <Link to="/about">About &nbsp;</Link>
                                    <Link to="/contact"> &nbsp; Contact &nbsp; </Link>
                                </li>
                            </ul>
                        </Columns.Column>
                        {docs}
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Content>
                                <p>
                                    <strong>© Model Broker</strong> 2023 by <a href="https://www.semantum.fi" target="_blank">Semantum </a>
                                    | <a href="/privacynotice" target="_blank">Privacy Notice</a>
                                </p>
                            </Content>
                        </Columns.Column>
                    </Columns>
                </Container>
            </Footer>
        </Hero.Footer>
    </Hero>
    );
}
