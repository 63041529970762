import { Link, withPrefix } from 'gatsby';
import React from 'react';
import { Container, Navbar } from 'react-bulma-components';


interface NavBarProps {
    isIndex: boolean;
}

interface NavBarState {
    active: boolean;
}


class NavBar extends React.Component<NavBarProps, NavBarState> {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState({
            active: !this.state.active,
        });
    }

    render() {
        const { isIndex } = this.props;
        /*const resourcesItem = false ? (
            <Navbar.Item dropdown hoverable href="#">
                <Navbar.Link arrowless={false}>
                    Resources
                </Navbar.Link>
                <Navbar.Dropdown>
                    <Navbar.Item renderAs={Link} to="/docs/tutorial">
                        Tutorial
                    </Navbar.Item>
                    <Navbar.Item renderAs={Link} to="/docs/">
                        Docs
                    </Navbar.Item>
                </Navbar.Dropdown>
            </Navbar.Item>
        ) : null;*/
        const resourcesItem = null;
        return (
            <Navbar active={this.state.active}>
                <Container style={{paddingTop: '20px', paddingBottom: '20px'}}>
                    <Navbar.Brand>
                        {//isIndex ? null :
                        <Navbar.Item>
                            <Link to="/">
                                <img src={`${withPrefix('/')}img/Model_Broker_logo.svg`} style={{margin: 0, height: '28px'}} />
                            </Link>
                        </Navbar.Item>
                        //)
                      }
                        <Navbar.Burger onClick={() => this.toggleHamburger()} />
                    </Navbar.Brand>
                    <Navbar.Menu>
                        <Navbar.Container align="right">
                            <Navbar.Item renderAs={Link} to="/about">
                                About
                            </Navbar.Item>
                            {resourcesItem}
                            <Navbar.Item renderAs={Link} to="/contact">
                                Contact
                            </Navbar.Item>
                        </Navbar.Container>
                    </Navbar.Menu>
                </Container>
            </Navbar>
        )
    }
}

export default NavBar;
