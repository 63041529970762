import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../index.sass'
import useSiteMetadata from '../queries/SiteMetadata'
import { withPrefix } from 'gatsby'

interface LayoutProps {
    children?: any;
    isIndex?: boolean;
}

const TemplateWrapper = (props: LayoutProps) => {
    const { children, isIndex } = props;
    const { title, titleDescription, image, siteUrl, titleTemplate, description } = useSiteMetadata()
    return (
        <React.Fragment>
            <Helmet titleTemplate={titleTemplate}>
                <html lang="en" />

                <title>{title}</title>
                <meta name="description" content={description} />

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix('/')}img/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon.svg`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon.svg`}
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
                    color="#ff4400"
                />
                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={siteUrl} />
                <meta
                    property="og:image"
                    content={siteUrl + image}
                />
                <meta name="google-site-verification" content="VAwwSL6ZG1CvQ0eyhvdOAgMR_H0vf8sh6TgwGPrFJpg" />
            </Helmet>
            <Navbar isIndex={isIndex} />
            <div>{children}</div>
            <Footer isIndex={isIndex} />
        </React.Fragment>
    )
}

export default TemplateWrapper
